/* General Reset */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

* {
  box-sizing: border-box;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Hero Section */
.hero-section {
  background-color: #002b5b;
  color: #ffffff;
  text-align: center;
  padding: 50px 20px;
  height: 100vh; /* Ensures it fills the full screen height */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically centers content */
  align-items: center; /* Horizontally centers content */
}

.hero-section .attention {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.hero-section .main-title {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 20px;
  text-align: center;
}

.hero-section .subheading {
  font-size: 1.2rem;
  margin-bottom: 30px;
  text-align: center;
}

.hero-section .cta-button {
  display: inline-block;
  background-color: #ffffff;
  color: #002b5b;
  padding: 15px 30px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.hero-section .cta-button:hover {
  background-color: #ffd700;
  color: #002b5b;
}

.hero-section .scarcity {
  font-size: 1rem;
  margin-top: 20px;
  font-style: italic;
  text-align: center;
}

/* Downward Arrow */
.down-arrow {
  position: absolute;
  bottom: 20px; /* Position it near the bottom of the hero section */
  color: #ffffff;
  font-size: 2rem;
  cursor: pointer;
  animation: bounce 2s infinite; /* Add a subtle bounce animation */
  transition: color 0.3s ease;
}

.down-arrow:hover {
  color: #ffd700; /* Highlight the arrow on hover */
}

/* Bounce Animation */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .hero-section {
    padding: 40px 15px;
  }

  .hero-section .attention {
    font-size: 1rem;
  }

  .hero-section .main-title {
    font-size: 2rem;
    line-height: 1.3;
  }

  .hero-section .subheading {
    font-size: 1rem;
  }

  .hero-section .cta-button {
    padding: 12px 20px;
    font-size: 0.9rem;
  }

  .hero-section .scarcity {
    font-size: 0.9rem;
  }

  .down-arrow {
    font-size: 1.8rem;
    bottom: 10px;
  }
}

@media (max-width: 480px) {
  .hero-section {
    padding: 30px 10px;
  }

  .hero-section .attention {
    font-size: 0.9rem;
  }

  .hero-section .main-title {
    font-size: 1.5rem;
    line-height: 1.2;
  }

  .hero-section .subheading {
    font-size: 0.9rem;
  }

  .hero-section .cta-button {
    padding: 10px 15px;
    font-size: 0.8rem;
  }

  .hero-section .scarcity {
    font-size: 0.8rem;
  }

  .down-arrow {
    font-size: 1.5rem;
    bottom: 5px;
  }
}

/* Conversion Section */
.conversion-section {
  background-color: #f9f9f9;
  color: #002b5b;
  padding: 50px 20px;
  line-height: 1.6;
}

.conversion-section .section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}

.conversion-section h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 15px;
}

.conversion-section ul {
  margin: 20px 0;
  padding-left: 20px;
}

.conversion-section ul li {
  margin-bottom: 10px;
  font-size: 1rem;
}

.conversion-section p {
  margin-bottom: 15px;
  font-size: 1rem;
}

.conversion-section .image-container {
  text-align: center;
  margin: 30px 0;
}

.conversion-section .results-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.conversion-section .cta-container {
  text-align: center;
  margin-top: 40px;
}

.conversion-section .cta-button {
  display: inline-block;
  background-color: #002b5b;
  color: #ffffff;
  padding: 15px 30px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.conversion-section .cta-button:hover {
  background-color: #ffd700;
  color: #002b5b;
}

.conversion-section .scarcity {
  margin-top: 15px;
  font-size: 0.9rem;
  font-style: italic;
  color: #ff0000;
}

/* How It Works Section */
.how-it-works-section {
  background-color: #f4f4f4;
  color: #002b5b;
  padding: 50px 20px;
  text-align: center;
}

.how-it-works-section .section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 30px;
}

.how-it-works-section .steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.how-it-works-section .step {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  max-width: 300px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.how-it-works-section .step:hover {
  transform: translateY(-5px);
}

.how-it-works-section .icon-container {
  margin-bottom: 15px;
}

.how-it-works-section .icon-container img {
  width: 50px;
  height: 50px;
}

.how-it-works-section h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.how-it-works-section p {
  font-size: 1rem;
  line-height: 1.5;
}

/* Last CTA Section */
.cta-section {
  text-align: center; /* Center aligns the content */
  margin: 50px 0; /* Adds vertical spacing */
}

.cta-section .cta-button {
  display: inline-block;
  background-color: #002b5b;
  color: #ffffff;
  padding: 15px 30px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.cta-section .cta-button:hover {
  background-color: #ffd700;
  color: #002b5b;
}

.cta-section .scarcity {
  font-size: 0.9rem;
  margin-top: 15px;
  font-style: italic;
  color: #ff0000; /* Emphasizes urgency */
}