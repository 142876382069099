/* General Styling */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Merriweather', serif;
}

/* Blog Post Page Styling */
.blog-post-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow-wrap: break-word;
    /* Ensures long words wrap within the container */
    word-wrap: break-word;
    /* Legacy support for older browsers */
    word-break: break-word;
}

.blog-post-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.blog-post-content iframe {
    max-width: 100%;
    /* Makes iframes responsive */
    height: auto;
    aspect-ratio: 16/9;
    /* Maintains aspect ratio for videos */
}

.blog-post-content img {
    max-width: 100%;
    /* Ensures images don't overflow the container */
    height: auto;
    border-radius: 8px;
    /* Optional: makes images more visually appealing */
}

.blog-post-image {
    width: 100%;
    max-height: 400px;
    object-fit: contain;
    /* Ensures the image fits without cropping */
    border-radius: 8px;
}

.blog-post-title {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
}

.blog-post-meta {
    font-size: 0.9em;
    color: #666;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.blog-post-meta span {
    display: block;
    /* Makes it mobile-friendly */
    margin-bottom: 5px;
}

.blog-post-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
    /* Centers tags on mobile */
    margin: 10px 0;
}

.blog-post-tag {
    background-color: #e1f5fe;
    color: #0288d1;
    padding: 5px 10px;
    font-size: 0.8em;
    border-radius: 20px;
}

.blog-post-content pre,
.blog-post-content code {
    white-space: pre-wrap;
    /* Ensures code blocks wrap within the container */
    word-wrap: break-word;
    /* Additional safety for wrapping */
    overflow-x: auto;
    /* Allows horizontal scroll for long lines of code */
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 4px;
    font-size: 0.9em;
    max-width: 100%;
}

.blog-post-content {
    line-height: 1.8;
    font-size: 1.1em;
    color: #444;
}

.blog-post-content h2,
.blog-post-content h3 {
    margin-top: 20px;
    color: #333;
}

.blog-post-content table {
    width: 100%;
    overflow-x: auto;
    /* Ensures tables don't overflow */
    display: block;
    /* Forces the table to act like a block element */
    margin: 20px 0;
}

.blog-post-content table th,
.blog-post-content table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
    word-wrap: break-word;
    /* Breaks long words within cells */
}

.blog-post-content table th {
    background-color: #f1f1f1;
    font-weight: bold;
}

/* Buttons and Links */
a {
    text-decoration: none;
    color: #0288d1;
}

a:hover {
    text-decoration: underline;
}

.back-button {
    display: inline-block;
    margin-top: 20px;
    background-color: #0288d1;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    width: auto;
}

.back-button:hover {
    background-color: #0277bd;
}

.back-button:focus {
    outline: 2px solid #0288d1;
    outline-offset: 2px;
}

/* Responsive Design */
@media (max-width: 480px) {
    .blog-post-title {
        font-size: 1.5em;
    }

    .blog-post-content {
        font-size: 0.9em;
        line-height: 1.5;
    }

    .blog-post-meta span {
        font-size: 0.7em;
    }

    .blog-post-tags {
        flex-wrap: wrap;
        gap: 6px;
    }

    .blog-post-tag {
        padding: 4px 8px;
        font-size: 0.7em;
    }

    .back-button {
        width: 100%;
        text-align: center;
        padding: 10px;
    }
}

/* Breadcrumb Styles */
.breadcrumb {
    font-size: 0.9rem;
    /* Adjust font size for readability */
    color: #555;
    /* Subtle color for breadcrumbs */
    margin: 10px 20px;
    /* Space around the breadcrumb */
    padding: 10px;
    /* Padding inside the breadcrumb container */
    background-color: #f9f9f9;
    /* Light background for visibility */
    border-radius: 5px;
    /* Rounded corners for a smooth look */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
}

.breadcrumb-link {
    text-decoration: none;
    /* Remove underline */
    color: #007bff;
    /* Blue color for links */
    font-weight: 500;
    /* Semi-bold text for emphasis */
    transition: color 0.3s ease;
    /* Smooth transition on hover */
}

.breadcrumb-link:hover {
    color: #0056b3;
    /* Darker blue on hover */
    text-decoration: underline;
    /* Add underline for hover effect */
}

.breadcrumb-separator {
    margin: 0 5px;
    /* Space around separators */
    color: #888;
    /* Neutral color for separators */
}

.breadcrumb-current {
    color: #333;
    /* Darker color for the current page */
    font-weight: bold;
    /* Bold text for emphasis */
}

.related-articles {
    margin-top: 40px;
  }
  
  .related-articles h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .related-articles-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .related-article-item {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .related-article-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .related-article-details {
    padding: 15px;
  }
  
  .related-article-details h3 {
    font-size: 1.2em;
    margin: 0;
    margin-bottom: 10px;
  }
  
  .related-article-details h3 a {
    text-decoration: none;
    color: #333;
  }
  
  .related-article-details h3 a:hover {
    color: #0288d1;
  }
  
  .related-article-details p {
    font-size: 0.9em;
    color: #666;
  }
  
  .view-more {
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;
    font-size: 1em;
    color: #0288d1;
  }
  
  .view-more:hover {
    text-decoration: underline;
  }  