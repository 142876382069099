/* General Styling */
.blog-page {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .blog-page-title {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 30px;
  }
  
  .blog-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* Blog Item Layout */
  .blog-item {
    display: flex;
    gap: 20px;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    align-items: center;
  }
  
  .blog-item-image {
    width: 150px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .blog-item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .blog-item-title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
    text-decoration: none;
  }
  
  .blog-item-title:hover {
    color: #0288d1;
  }
  
  .blog-item-meta {
    font-size: 0.9em;
    color: #555;
    margin-bottom: 10px;
  }
  
  .blog-item-date {
    font-size: 0.9em;
    color: #777;
    margin-bottom: 10px;
  }
  
  /* Read Now Button */
  .read-now-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 0.9em;
    color: #ffffff;
    background-color: #0288d1;
    border: none;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    width: fit-content;
  }
  
  .read-now-button:hover {
    background-color: #0277bd;
  }
  
  /* Load More Button */
  .load-more-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 1em;
    color: #ffffff;
    background-color: #0288d1;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .load-more-button:hover {
    background-color: #0277bd;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .blog-item {
      flex-direction: column;
      text-align: center;
    }

    .read-now-button{
        width: 100%;
    }
  
    .blog-item-image {
      width: 100%;
      height: auto;
    }
  }  